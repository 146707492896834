@import url('https://fonts.googleapis.com/css?family=Fira+Sans:500|Frank+Ruhl+Libre:400,900');

:root {
    font-family: 'Fira Sans', sans-serif;
    font-size: 14px;
}

h2 {
    font-size: 16px;
    font-weight: 500;
}

h3 {
    font-size: 14px;
    font-weight: 500;
}

p {
    font-size: 16px;
    font-weight: 300;
}

label {
    color: white;
}