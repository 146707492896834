.search {
    background: $darkgrey;
    border: none; 
    height: 2rem; 
    font-size: 15px;
    padding: 0 1rem; 
    width: 100%;
    outline: none;
    margin: 1rem 0;
    box-sizing: border-box;
}