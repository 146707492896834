.page-wrapper, .categories-wrapper {
    margin: 0;
    padding: 0;
    border-bottom: $darkgrey dotted 1px;
    color: $dark; 

    section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .column {
            flex: 1;
            display: flex;
            flex-direction: column;
            
            .blue-column {
                flex: 1;
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                justify-content: flex-start;

                h3 {
                    font-size: 18px;
                }
            }

            .extra-info, .categories {
                padding: 0 0 0 5rem;
                display: flex;
                margin: 0;
                align-items: center;
            }
        }
    }
}