// background-colors
$be-pink: #EF8396;
$be-green: #75B799;
$be-blue: #00697C;
$lightgrey: #F5F5F5;
$darkgrey: #DBDBDB;

// text-color
$light: #fff;
$dark: #000;
$pdark: rgba(0,0,0,.7);
$plight: rgba(255,255,255,.7);