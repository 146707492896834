@import url(https://fonts.googleapis.com/css?family=Fira+Sans:500|Frank+Ruhl+Libre:400,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

:root {
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px; }

h2 {
  font-size: 16px;
  font-weight: 500; }

h3 {
  font-size: 14px;
  font-weight: 500; }

p {
  font-size: 16px;
  font-weight: 300; }

label {
  color: white; }

button, a {
  outline: none;
  text-decoration: none; }

.btn-login {
  background: #EF8396;
  min-height: 4rem;
  border: none;
  color: white;
  margin: 3rem 0 0 0;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer; }

.btn-publish {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #75B799;
  border: solid 1px white;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
  padding: .5rem 4rem; }

.btn-close {
  background-color: #00697C;
  align-items: center;
  border: solid 1px white;
  color: white;
  font-size: 14px;
  padding: .5rem 4rem;
  cursor: pointer; }

.btn-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem 1rem;
  background-color: #F5F5F5;
  border: black solid 2px;
  cursor: pointer;
  margin: 1rem auto; }

.btn-logout {
  text-transform: uppercase;
  color: black;
  margin: 1rem 5rem; }

.back {
  grid-area: back;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black; }

.page-wrapper, .categories-wrapper {
  margin: 0;
  padding: 0;
  border-bottom: #DBDBDB dotted 1px;
  color: #000; }
  .page-wrapper section, .categories-wrapper section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .page-wrapper section .column, .categories-wrapper section .column {
      flex: 1 1;
      display: flex;
      flex-direction: column; }
      .page-wrapper section .column .blue-column, .categories-wrapper section .column .blue-column {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: flex-start; }
        .page-wrapper section .column .blue-column h3, .categories-wrapper section .column .blue-column h3 {
          font-size: 18px; }
      .page-wrapper section .column .extra-info, .page-wrapper section .column .categories, .categories-wrapper section .column .extra-info, .categories-wrapper section .column .categories {
        padding: 0 0 0 5rem;
        display: flex;
        margin: 0;
        align-items: center; }

input {
  outline: none;
  padding: .5rem;
  font-size: 14px; }

.input {
  border: none;
  height: 3rem;
  outline: none;
  padding: 0 10px;
  font-size: 20px; }

.dropdown {
  display: flex;
  flex-direction: column; }
  .dropdown select {
    background-color: white;
    font-size: 15px;
    height: 2rem;
    outline: none;
    margin: 0 0 .5rem 0;
    border: white solid; }

textarea {
  border: none;
  width: 100%;
  height: 10rem;
  padding: .5rem;
  font-size: 14px;
  resize: none;
  outline: none;
  box-sizing: border-box; }

.search {
  background: #DBDBDB;
  border: none;
  height: 2rem;
  font-size: 15px;
  padding: 0 1rem;
  width: 100%;
  outline: none;
  margin: 1rem 0;
  box-sizing: border-box; }

.wrapper-login {
  min-height: 100vh;
  display: grid;
  background: black url(/static/media/front.4e055c5a.jpg) no-repeat bottom left/cover;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 12rem auto;
  grid-template-areas: 'logo . . .' '. login login .'; }
  .wrapper-login .logo {
    grid-area: logo;
    background: url("/static/media/BE Logotyp_White.300e81ba.png") no-repeat;
    background-size: 8rem;
    margin: 3rem; }
  .wrapper-login > div {
    grid-area: login; }
    .wrapper-login > div form h1 {
      font-size: 5rem;
      margin: 0 0 3rem 0;
      color: white; }
    .wrapper-login > div .enter {
      text-transform: uppercase;
      width: 25rem;
      display: flex;
      flex-direction: column;
      font-size: 20px; }
      .wrapper-login > div .enter label {
        margin: .3rem 0; }

.wrapper-menu {
  background-color: #EF8396;
  display: flex;
  justify-content: space-between; }
  .wrapper-menu .logo {
    background: url("/static/media/BE Logotyp_Black.dc6eea7d.png") no-repeat left center;
    background-size: 3rem;
    padding: 0 2rem;
    margin: 0 2rem; }

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 'lista lista add-form'; }
  .content .lista {
    grid-area: lista; }
    .content .lista h1 {
      margin: 3rem 0 0 14rem; }
  .content .wrapper-list {
    background-color: #F5F5F5;
    padding: 1rem 4rem;
    margin: 3rem 3rem 3rem 10rem; }
    .content .wrapper-list .categories-wrapper {
      border-bottom: solid 2px; }
    .content .wrapper-list .start-list {
      margin: 2rem;
      text-decoration: none; }

form h1 {
  margin: 3rem; }

form .wrapper-add {
  background-color: #75B799;
  margin: 3rem 10rem 0 0; }
  form .wrapper-add .add-form {
    grid-area: add-form;
    color: white;
    padding: 2rem 3rem;
    text-transform: uppercase; }
    form .wrapper-add .add-form h3 {
      margin: .3rem 0; }
    form .wrapper-add .add-form h2 {
      margin: .5rem 0; }
    form .wrapper-add .add-form h5 {
      margin: .5rem; }
    form .wrapper-add .add-form .add-write {
      display: flex;
      flex-direction: column;
      margin: 0 0 1rem 0; }
      form .wrapper-add .add-form .add-write input {
        height: 2rem;
        border: none; }
      form .wrapper-add .add-form .add-write p {
        margin: .7rem 0 0 0; }

.wrapper-case {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 'back topic topic . .' '. issue issue info info' '. list-comment list-comment close close' '. text text . .' '. addComment addComment . .'; }
  .wrapper-case .topic {
    grid-area: topic;
    text-transform: uppercase;
    margin: 2rem; }
    .wrapper-case .topic h1 {
      margin: 1rem 0; }
    .wrapper-case .topic h2 {
      color: #75B799;
      margin: 0; }
  .wrapper-case .issue {
    grid-area: issue;
    background-color: #F5F5F5;
    padding: 1rem 2rem;
    margin: 0 1rem 1rem 0; }
  .wrapper-case .info {
    grid-area: info;
    min-width: 14rem;
    background-color: #DBDBDB;
    padding: 1rem 3rem;
    margin: 0 12rem 1rem 3rem; }
  .wrapper-case .text {
    grid-area: text;
    font-size: 18px;
    padding: 1rem 4rem;
    margin: 0;
    text-transform: uppercase; }
  .wrapper-case .list-comment {
    grid-area: list-comment; }
    .wrapper-case .list-comment #comments {
      margin: 0;
      list-style-type: none;
      padding: 0 1rem;
      padding-left: 0; }
      .wrapper-case .list-comment #comments .com:nth-child(odd) {
        margin: 0 0 .5rem 0;
        padding: 1rem 2rem;
        background-color: #DBDBDB; }
      .wrapper-case .list-comment #comments .com:nth-child(even) {
        margin: .5rem 0;
        padding: 1rem 2rem;
        background-color: #F5F5F5; }
      .wrapper-case .list-comment #comments > div {
        margin: 2rem 0; }
  .wrapper-case .close {
    grid-area: close;
    min-width: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #00697C;
    padding: 1rem 3rem;
    margin: 0 12rem 1rem 3rem;
    max-height: 5rem; }
    .wrapper-case .close h2 {
      margin: 0 0 .5rem 0;
      color: white; }
  .wrapper-case .addComment {
    grid-area: addComment;
    background-color: #75B799;
    padding: .5rem 4rem;
    margin: 0 1rem 1rem 0; }
    .wrapper-case .addComment h2 {
      color: white;
      text-transform: uppercase;
      margin: .5rem 0; }
    .wrapper-case .addComment .btn-publish {
      float: right;
      margin: 1rem 0; }
    .wrapper-case .addComment textarea {
      padding: 10px;
      height: 8rem;
      display: flex; }

@media (max-width: 720px) {
  .wrapper-menu .logo {
    margin: .5rem; }
  .wrapper-menu .btn-logout {
    margin: auto 1rem; }
  .wrapper-login {
    grid-template-columns: 1fr;
    grid-template-rows: 4rem auto;
    grid-template-areas: 'logo' 'login'; }
    .wrapper-login .logo {
      grid-area: logo;
      background-size: 2rem;
      margin: 1rem; }
    .wrapper-login > div form {
      margin: 0 3rem; }
      .wrapper-login > div form h1 {
        font-size: 35px; }
    .wrapper-login > div .enter {
      width: 100%;
      font-size: 14px; }
      .wrapper-login > div .enter .input {
        font-size: 18px; }
  .content {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas: 'add-form' 'lista'; }
    .content .lista h1 {
      margin: 3rem; }
    .content .wrapper-list {
      margin: 1rem;
      padding: 3rem; }
      .content .wrapper-list .page-wrapper section .column .categories, .content .wrapper-list .categories-wrapper section .column .categories {
        flex-direction: column;
        margin: 0;
        padding: 0; }
      .content .wrapper-list .page-wrapper section .column .extra-info, .content .wrapper-list .categories-wrapper section .column .extra-info {
        padding: 0;
        display: flex;
        flex-direction: column; }
  form .wrapper-add {
    margin: 1rem; }
    form .wrapper-add .add-form {
      padding: 2rem; }
  .wrapper-case {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'back' 'topic' 'issue' 'list-comment' 'text' 'addComment' 'info' 'close'; }
    .wrapper-case .issue, .wrapper-case .info, .wrapper-case .text, .wrapper-case .list-comment, .wrapper-case .close, .wrapper-case .addComment {
      margin: 1rem;
      padding: 1rem; }
    .wrapper-case .info, .wrapper-case .close {
      display: flex;
      flex-direction: column;
      margin: 1rem auto;
      padding: 2rem; }
    .wrapper-case #comments {
      margin: 1rem; } }

.cssload-container * {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

.cssload-container {
  margin: 19px auto 0 auto;
  max-width: 545px; }

.cssload-container ul li {
  list-style: none; }

.cssload-flex-container {
  display: flex;
  display: -o-flex;
  display: -ms-flex;
  display: -moz-flex;
  flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  justify-content: space-around; }

.cssload-flex-container li {
  padding: 10px;
  height: 97px;
  width: 97px;
  margin: 29px 19px;
  position: relative;
  text-align: center; }

.cssload-loading, .cssload-loading:after, .cssload-loading:before {
  display: inline-block;
  position: relative;
  width: 5px;
  height: 49px;
  background: black;
  margin-top: 5px;
  border-radius: 975px;
  -o-border-radius: 975px;
  -ms-border-radius: 975px;
  -webkit-border-radius: 975px;
  -moz-border-radius: 975px;
  animation: cssload-upDown2 1.15s ease infinite;
  -o-animation: cssload-upDown2 1.15s ease infinite;
  -ms-animation: cssload-upDown2 1.15s ease infinite;
  -webkit-animation: cssload-upDown2 1.15s ease infinite;
  -moz-animation: cssload-upDown2 1.15s ease infinite;
  animation-direction: alternate;
  -o-animation-direction: alternate;
  -ms-animation-direction: alternate;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  animation-delay: 0.29s;
  -o-animation-delay: 0.29s;
  -ms-animation-delay: 0.29s;
  -webkit-animation-delay: 0.29s;
  -moz-animation-delay: 0.29s; }

.cssload-loading:after, .cssload-loading:before {
  position: absolute;
  content: '';
  animation: cssload-upDown 1.15s ease infinite;
  -o-animation: cssload-upDown 1.15s ease infinite;
  -ms-animation: cssload-upDown 1.15s ease infinite;
  -webkit-animation: cssload-upDown 1.15s ease infinite;
  -moz-animation: cssload-upDown 1.15s ease infinite;
  animation-direction: alternate;
  -o-animation-direction: alternate;
  -ms-animation-direction: alternate;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate; }

.cssload-loading:before {
  left: -10px; }

.cssload-loading:after {
  left: 10px;
  animation-delay: 0.58s;
  -o-animation-delay: 0.58s;
  -ms-animation-delay: 0.58s;
  -webkit-animation-delay: 0.58s;
  -moz-animation-delay: 0.58s; }

@keyframes cssload-upDown {
  from {
    -webkit-transform: translateY(19px);
            transform: translateY(19px); }
  to {
    -webkit-transform: translateY(-19px);
            transform: translateY(-19px); } }

@-webkit-keyframes cssload-upDown {
  from {
    -webkit-transform: translateY(19px); }
  to {
    -webkit-transform: translateY(-19px); } }

@keyframes cssload-upDown2 {
  from {
    -webkit-transform: translateY(29px);
            transform: translateY(29px); }
  to {
    -webkit-transform: translateY(-19px);
            transform: translateY(-19px); } }

@-webkit-keyframes cssload-upDown2 {
  from {
    -webkit-transform: translateY(29px); }
  to {
    -webkit-transform: translateY(-19px); } }

