@import './base/_typography.scss';
@import './base/_variables.scss';
@import './components/_btn.scss';
@import './components/_post.scss';
@import './layout/_forms.scss';
@import './layout/search.scss';

$baseline: 4rem; 

.wrapper-login {
    min-height: 100vh;
    display: grid; 
    background: black url("../img/front.jpg") no-repeat bottom left / cover;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: (3 * $baseline) auto;
    grid-template-areas: 
    'logo . . .'
    '. login login .';
    
    .logo {
        grid-area: logo;
        background: url('../img/BE\ Logotyp_White.png') no-repeat;
        background-size: 8rem; 
        margin: 3rem;
    }

    &>div {
        grid-area: login;
        
        form {
            h1 {
                font-size: 5rem; 
                margin: 0 0 3rem 0;
                color: white;
            }
        }

        .enter {
            text-transform: uppercase;
            width: 25rem;
            display: flex;
            flex-direction: column;
            font-size: 20px;

            label {
                margin: .3rem 0;
            }
        }
    }
}

.wrapper-menu {
    background-color: $be-pink;
    display: flex;
    justify-content: space-between;

    .logo {
        background: url('../img/BE\ Logotyp_Black.png') no-repeat left center ;
        background-size: 3rem; 
        padding: 0 2rem;
        margin: 0 2rem; 
    }
}

.content {
    display: grid; 
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
        'lista lista add-form';

    .lista {
        grid-area: lista;
        
        h1 {
            margin: 3rem 0 0 14rem;
        }
    }
    
    .wrapper-list {
        background-color: $lightgrey;
        padding: 1rem 4rem;
        margin: 3rem 3rem 3rem 10rem;

        .categories-wrapper {
            border-bottom: solid 2px;
          
        }
        
        .start-list {
            margin: 2rem; 
            text-decoration: none;
        }
    }
}

form {
    h1 {
        margin: 3rem;
    }

    .wrapper-add {
        background-color: $be-green; 
        margin: 3rem 10rem 0 0;
        
        .add-form {
            grid-area: add-form;
            color: white;
            padding: 2rem 3rem;
            text-transform: uppercase;
            
            h3 {
                margin: .3rem 0;
            }

            h2 {
                margin: .5rem 0;
            }

            h5 {
                margin: .5rem;
            }
    
            .add-write {
                display: flex; 
                flex-direction: column;
                margin: 0 0 1rem 0; 


                input {
                    height: 2rem;
                    border: none;
                }
    
                p {
                    margin: .7rem 0 0 0; 
                }
            }
        } 
    }
}


.wrapper-case {
    width: 100%; 
    display: grid; 
    grid-template-columns: repeat(5, 1fr); 
    grid-template-rows: auto ; 
    grid-template-areas: 
        'back topic topic . .'
        '. issue issue info info'
        '. list-comment list-comment close close'
        '. text text . .'
        '. addComment addComment . .';

    .topic {
        grid-area: topic;
        text-transform: uppercase;
        margin: 2rem;

        h1 {
            margin: 1rem 0; 
        }

        h2 {
            color: $be-green; 
            margin: 0;
        }
    }
    .issue {
        grid-area: issue; 
        background-color: $lightgrey;
        padding: 1rem 2rem;
        margin: 0 1rem 1rem 0;
    } 
    .info {
        grid-area: info; 
        min-width: 14rem; 
        background-color: $darkgrey;
        padding: 1rem 3rem;
        margin: 0 12rem 1rem 3rem;
    }
    .text {
        grid-area: text;
        font-size: 18px;
        padding: 1rem 4rem; 
        margin: 0;
        text-transform: uppercase;
    }

    .list-comment {
        grid-area: list-comment; 

        
        #comments {
            margin: 0; 
            list-style-type: none;
            padding: 0 1rem;
            padding-left: 0;
            
                .com:nth-child(odd) {
                    margin: 0 0 .5rem 0; 
                    padding: 1rem 2rem;
                    background-color: $darkgrey;
                }
                .com:nth-child(even) {
                    margin: .5rem 0; 
                    padding: 1rem 2rem;
                    background-color: $lightgrey;
                }
            

            &>div {
                margin: 2rem 0;      
            }
        }
    }
    .close {
        grid-area: close; 
        min-width: 14rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $be-blue;
        padding: 1rem 3rem;
        margin: 0 12rem 1rem 3rem;
        max-height: 5rem;

        h2 {
            margin: 0 0 .5rem 0;
            color: white;
        }
    }
    .addComment {
        grid-area: addComment;
        background-color: $be-green;
        padding: .5rem 4rem;
        margin: 0 1rem 1rem 0;

        h2 {
            color: white; 
            text-transform: uppercase;
            margin: .5rem 0;
        }

        .btn-publish {
            float: right;
            margin: 1rem 0; 
        }

        textarea {
            padding: 10px;
            height: 8rem; 
            display: flex;
        }
    }
}


@media (max-width: 720px) {
    .wrapper-menu {
        .logo {
            margin: .5rem;
        }
        .btn-logout {
            margin: auto 1rem;
        }
    }
    .wrapper-login {
        grid-template-columns: 1fr;
        grid-template-rows: $baseline auto;
        grid-template-areas: 
        'logo'
        'login';
        
        .logo {
            grid-area: logo;
            background-size: 2rem; 
            margin: 1rem;
        }
        
        &>div {
            
            form {
                margin: 0 3rem;
                
                h1 {
                    font-size: 35px; 
                }
            }
            .enter {
                width: 100%;
                font-size: 14px;

                .input {    
                    font-size: 18px;
                }
            }
        }
    }

    .content {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-template-areas:
            'add-form'
            'lista';

        .lista {
            h1 {
                margin: 3rem;
            }
        }
        .wrapper-list {
            margin: 1rem; 
            padding: 3rem; 


            .page-wrapper, .categories-wrapper {
                section {
                    
                    .column {
                        .categories {
                            flex-direction: column;
                            margin: 0;
                            padding: 0;
                        }

                        .extra-info {
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
    form {
        .wrapper-add {
            margin: 1rem; 

            .add-form {
                padding: 2rem; 
            }
        }
    }

    .wrapper-case {
        grid-template-columns: 1fr; 
        grid-template-rows: auto ; 
        grid-template-areas: 
            'back'
            'topic'
            'issue'
            'list-comment'
            'text'
            'addComment'
            'info'
            'close';

        .issue, .info, .text, .list-comment, .close, .addComment {
            margin: 1rem; 
            padding: 1rem;
        }

        .info, .close {
            display: flex;
            flex-direction: column;
            margin: 1rem auto;
            padding: 2rem; 
        }
        #comments {
            margin: 1rem; 
        }
    }
}