button, a {
    outline: none;
    text-decoration: none; 
}

.btn-login {
    background: $be-pink;
    min-height: 4rem;;
    border: none;
    color: white;
    margin: 3rem 0 0 0;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-publish {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $be-green; 
    border: solid 1px white; 
    color: white; 
    font-size: 16px;
    cursor: pointer;
    margin: 0 auto;
    padding: .5rem 4rem;
}

.btn-close {
    background-color: $be-blue;
    align-items: center;
    border: solid 1px white; 
    color: white; 
    font-size: 14px;
    padding: .5rem 4rem;
    cursor: pointer;
}

.btn-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    background-color: $lightgrey;
    border: black solid 2px; 
    cursor: pointer;
    margin: 1rem auto;
}

.btn-logout {
    text-transform: uppercase;
    color: black;
    margin: 1rem 5rem;
}

.back {
    grid-area: back;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}