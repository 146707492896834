input {
    outline: none;
    padding: .5rem;
    font-size: 14px;
}

.input {
    border: none; 
    height: 3rem;
    outline: none;
    padding: 0 10px;
    font-size: 20px;
}

.dropdown {
    display: flex;
    flex-direction: column;
    
    select {
        background-color: white;  
        font-size: 15px; 
        height: 2rem;
        outline: none;
        margin: 0 0 .5rem 0;
        border: white solid; 
    }
}

textarea {
    border: none; 
    width: 100%;
    height: 10rem;
    padding: .5rem;
    font-size: 14px;
    resize: none;
    outline: none;
    box-sizing: border-box;
}